import fetchAPI from '@/api/index';
import { UserProfile } from '@/types/user';

export function fetchProfile(userId?: string): Promise<UserProfile> {
  if (userId === 'undefined' || userId === undefined) {
    return fetchAPI(`profile`);
  } else {
    return fetchAPI(`profile/${userId}`);
  }
}
