import { useEffect, useState } from 'react';
import fetchAPI from '@/api';
import { CustomProject } from '@/types/project';

export function useProjectList(tenantId?: string) {
  const [projects, setProjects] = useState<CustomProject[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchProjects() {
      setIsLoading(true);
      try {
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetchAPI(`project/list/${tenantId}`, requestOptions);

        const projectList: CustomProject[] = await response; // JSONからテナントのリストを取得
        // projectList.pinnedを先頭にするようにソートする
        // TODO: 後々、ソート条件を増やす場合はこの辺の処理を拡張する
        const sortedProjectList = projectList.sort((a, b) =>
          a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1
        );
        setProjects(sortedProjectList);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error('An unexpected error occurred'));
        }
        setProjects([]);
      } finally {
        setIsLoading(false);
      }
    }

    if (tenantId) {
      fetchProjects();
    }
  }, [tenantId]);

  return { projects, error, isLoading };
}
