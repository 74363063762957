import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import styles from './ProjectItem.module.scss';

export default function ProjectItem() {
  return (
    <div className={styles.wrap}>
      <div className="flex p-2 gap-2 items-center">
        <Skeleton variant="rounded" width={50} height={50} style={{ minWidth: 50 }} />
        <div className="w-full flex-col text-left flex items-center">
          <h3 className={clsx('w-full text-base font-bold', styles.name)}>
            <Skeleton variant="text" width={200} />
          </h3>
          <div className={clsx('w-full text-sm text-right', styles.due_date)}>
            <Skeleton variant="text" width={100} />
          </div>
        </div>
      </div>
    </div>
  );
}
