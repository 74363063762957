import type { ErrorResponse } from '@/types/api';

type FetchOptions = {
  headers?: Record<string, string>;
  method?: string;
  body?: string | FormData;
};

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

async function fetchAPI(endpoint: string, options?: FetchOptions): Promise<any> {
  const url = `${BASE_URL}${endpoint}`;

  const defaultHeaders = {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${localStorage.getItem('token')}`, // トークンを含める
  };

  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...(options?.headers || {}),
      },
    });

    // ステータスコードに基づく追加の処理
    if (response.status === 401) {
      // if (window.location.pathname !== '/login') {
      //   window.location.href = '/login';
      //   return;
      // }
    }

    // 異常レスポンスの処理
    if (!response.ok) {
      const errorResponse: ErrorResponse = await response.json();
      throw new Error(errorResponse.error);
    }

    // レスポンスの形式を柔軟に処理
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response.text();
    }
  } catch (error) {
    throw new Error(`Something happened from API: ${error}`);
  }
}

export default fetchAPI;
