import StarIcon from '@mui/icons-material/Star';
import clsx from 'clsx';
import Link from 'next/link';

import { Icon } from '@/components/common/Icon';
import { CustomProject } from '@/types/project';
import { getYYYYMMDD } from '@/utils/date';
import styles from './ProjectItem.module.scss';

export default function ProjectItem({ project }: { project: CustomProject }) {
  return (
    <div className={styles.wrap}>
      <Link href={`/project/${project.id}`} className="flex p-2 gap-2 items-center">
        <Icon name="icon-project" className="block" />
        <div className="w-full flex-col text-left flex items-center">
          <h3 className={clsx('w-full text-base font-bold', styles.name)}>{project.name}</h3>
          {project.description && <p className="w-full text-left text-sm">{project.description}</p>}
          {project.endDate && (
            <div className={clsx('w-full text-sm', styles.due_date)}>
              ～{getYYYYMMDD(project.endDate)}
            </div>
          )}
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          {project.pinned && <StarIcon sx={{ width: 20, height: 20 }} htmlColor="orange" />}
          <div className={styles['-arrow-right']} />
        </div>
      </Link>
    </div>
  );
}
