import { format } from 'date-fns';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import jaLocale from 'date-fns/locale/ja';

/**
 * DBの日付項目からyyyy-mm-ddを取得する
 */
export const getYYYYMMDD = (date: Date): string => {
  // new Date(date)がパースできるか確認する
  if (isNaN(new Date(date).getTime())) {
    return '';
  }
  return format(new Date(date), 'yyyy-MM-dd');
};

export const getYYYYMMDDWithSlash = (date: Date): string => {
  // new Date(date)がパースできるか確認する
  if (isNaN(new Date(date).getTime())) {
    return '';
  }
  return format(new Date(date), 'yyyy/MM/dd');
};

/**
 * 現在からの期間をフォーマットして返す
 *
 * (`date-fns`のデフォルトから少し変えている)
 *
 * @example "1秒前" "24分前" "3時間前" "4日前" "5ヶ月前" "6年前"
 */
export const formatDistanceCustom = (date: Date) => {
  const result = formatDistanceToNowStrict(date, { locale: jaLocale });

  if (result.includes('秒')) return result + '前';

  if (result.includes('分')) return result + '前';

  if (result.includes('時間')) return result + '前';

  return format(new Date(date), 'yyyy/MM/dd HH:mm');
};
