import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import fetchAPI from '@/api';
import { taskListAtom } from '@/hooks/state/taskListState';
import { taskParamsAtom } from '@/hooks/state/taskParamstState';
import { TaskListType, TaskParamsType } from '@/types/task';

export function useTaskList(dontFetch?: boolean) {
  const [tasks, setTasks] = useAtom(taskListAtom);
  const [taskParams, setTaskParams] = useAtom(taskParamsAtom);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const [error, setError] = useState<Error | null>(null);

  const fetchTasks = async (taskParams: TaskParamsType) => {
    if (!taskParams.projectId) {
      return;
    }

    // TODO: 共通化すべき
    // GET用パラメーターを作成する
    const urlParams = new URLSearchParams();
    Object.keys(taskParams).forEach((key) => {
      const value = taskParams[key as keyof TaskParamsType];
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(key, v.toString()));
      } else if (value !== undefined && value !== null) {
        urlParams.set(key, value.toString());
      }
    });

    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetchAPI(`task?${urlParams.toString()}`, requestOptions);

      const taskList: TaskListType[] = await response;
      setTasks(taskList);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unexpected error occurred'));
      }
      setTasks([]);
    }
  };

  useEffect(() => {
    if (isFirstFetch && dontFetch) {
      setIsFirstFetch(false);
      return;
    }
    fetchTasks(taskParams);
    setIsFirstFetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskParams]);

  return { tasks, setTasks, error, taskParams, setTaskParams, fetchTasks };
}
