import React from 'react';
import clsx from 'clsx';
import styles from './Icon.module.scss';

type IconProps = React.ComponentPropsWithoutRef<'span'> & {
  /**
   * 各アイコン用クラス名から、最初のハイフンを抜いたもの
   *
   * ***
   *
   * 1 - アイコンが当たっているクラス名を調べる
   *
   * @example
   *
   * ```scss
   * // _icn.scss
   * &.-product {
   *   width: 16px;
   *   height: 16px;
   *   background-image: url('/assets/small_products.svg');
   * }
   * ```
   *
   * 2 - 最初のハイフンを抜かして適用する
   *
   * @example
   *
   * ```tsx
   * <Icon name="product" />
   * ```
   */
  name: string;
  /**
   * width, heightの一括指定
   */
  size?: React.CSSProperties['width'] | React.CSSProperties['height'];
};

/**
 * `_icn.scss`のクラスによるアイコン要素作成を簡略化するコンポーネント
 *
 * @example
 * ```tsx
 *
 * // before
 * <span className="c-icn -product" />
 *
 * // after
 * <Icon name="product" />
 * ```
 */
export function Icon(props: IconProps) {
  const { size, name, className, style, ...rest } = props;

  return (
    <span
      className={clsx(styles['-icon'], styles[`-${name}`], className)}
      style={size ? { width: size, height: size, ...style } : { ...style }}
      {...rest}
    />
  );
}
