import { useCallback, useEffect } from 'react';
import { Tenant } from '@prisma/client';
import { useAtom } from 'jotai';
import { fetchProfile } from '@/api/endpoints/profile';
import { currentTenantState } from '@/hooks/state/currentTenantState';
import { tenantsState } from '@/hooks/state/tenantsState';
import { CURRENT_TENANT_ID_KEY } from '@/utils/localstorage-key';

export const useTenant = () => {
  const [tenants, setTenants] = useAtom(tenantsState);
  const [currentTenant, _setCurrentTenant] = useAtom(currentTenantState);

  const setCurrentTenant = useCallback(
    (tenant: Tenant) => {
      _setCurrentTenant(tenant);
      localStorage.setItem(CURRENT_TENANT_ID_KEY, tenant.id);
    },
    [_setCurrentTenant]
  );

  useEffect(() => {
    async function fetchAuth() {
      try {
        if (!tenants) {
          const data = await fetchProfile();
          setTenants(data.UserTenant.map((userTenant) => userTenant.Tenant));
          // currentTenantが空だった場合は、1つ目のテナントを登録しておく
          if (currentTenant === null && data.UserTenant.length > 0) {
            const savedCurrentTenantId = localStorage.getItem(CURRENT_TENANT_ID_KEY);
            if (savedCurrentTenantId) {
              const savedCurrentTenant = data.UserTenant.find(
                (userTenant) => userTenant.Tenant.id === savedCurrentTenantId
              );
              if (savedCurrentTenant) {
                setCurrentTenant(savedCurrentTenant.Tenant);
              } else {
                setCurrentTenant(data.UserTenant[0].Tenant);
              }
            } else if (data.UserTenant[0].Tenant.id) {
              setCurrentTenant(data.UserTenant[0].Tenant);
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }

    if (tenants === null) {
      fetchAuth();
    }
  }, [currentTenant, _setCurrentTenant, setTenants, tenants, setCurrentTenant]);

  return { tenants, currentTenant, setCurrentTenant };
};
